@import '../../../../../../styles/variables.scss';

.vacancyCard {
	padding: 32px 36px;
}

.title {
	color: $black;
	font-family: Open Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin: 0;
}

.conditions {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	padding-top: 16px;
	margin: 0;
}

.button {
	display: flex;
	padding: 0px 8px 0px 24px;
	align-items: center;
	width: fit-content;
	border-radius: 32px;
	background: rgba(173, 215, 246, 0.5);
	margin-top: 32px;
	border: none;
	cursor: pointer;
	transition: 0.3s ease;
}

.button:hover {
	background: rgba(173, 215, 246, 0.75);
}

.buttonText {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	margin: 0;
}

.closeBtnOverlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
}

.modal {
	padding: 40px 40px 48px;
	background-color: $white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 576px;
	border-radius: 40px;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media all and (max-width: 767px) {
	.vacancyCard {
		padding: 24px;
	}

	.title {
		font-size: 24px;
		line-height: 1.25;
	}

	.conditions {
		font-size: 16px;
		line-height: 1.5;
		padding-top: 8px;
	}

	.button {
		padding: 8px 12px 8px 16px;
		margin-top: 16px;
		gap: 4px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	.buttonText {
		font-size: 16px;
		line-height: 1.5;
	}
}
