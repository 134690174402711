@import '../../styles/variables.scss';

.footer {
	background-color: $darkBlue;
}

.content {
	width: $desktopPageWidth;
	margin: 0 auto;
	padding: 64px 10px 84px;
	display: grid;
	grid-template-areas: 'contacts anchors servicesWrapper productsWrapper';
}

.contacts {
	display: flex;
	flex-direction: column;
	gap: 62px;
	grid-area: contacts;
}

.links {
	display: flex;
	flex-direction: row;
	gap: 32px;
	align-items: center;

	& > svg path {
		fill: $white;
	}
}

.socials {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
}

.contactsInfo {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.contact {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	color: $white;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 1.4;
	width: 534px;
}

.contact:nth-child(2) {
	text-decoration: underline;
}

.anchors {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 24px;
	grid-area: anchors;
	cursor: pointer;
}

.servicesWrapper {
	grid-area: servicesWrapper;
}

.productsWrapper {
	grid-area: productsWrapper;
}

.anchor,
.servicesTitle,
.productsTitle {
	color: $white;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.43;
	text-decoration: none;
	margin: 0;
}

.servicesWrapper,
.productsWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.services,
.products {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.service,
.product {
	color: $white;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.44;
}

.logo {
	width: 32px;
	flex-shrink: 0;
}

@media all and (max-width: 767px) {
	.content {
		width: $mobilePageWidth;
		padding: 32px 0 40px;
		grid-template-areas:
			'contacts contacts'
			'anchors anchors'
			'servicesWrapper productsWrapper';
	}
	.contacts {
		gap: 24px;
		margin-bottom: 40px;
	}
	.contact {
		width: auto;
		font-size: 16px;
	}
	.anchors {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 24px;
		grid-area: anchors;
		margin-bottom: 24px;
	}
	.logo {
		width: 24px;
	}
}
