@import-normalize;
@import './styles/app.scss';

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	font-weight: inherit;
	font-size: inherit;
}

a:link,
a:visited,
a:hover,
a:active {
	text-decoration: none;
	color: inherit;
}

h1 {
	font-family: 'Open Sans', sans-serif;
}
