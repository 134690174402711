@import '../../../../styles/variables.scss';

.content {
	width: $desktopPageWidth;
	margin: 0 auto;
	padding: 171px 10px 96px;
	display: flex;
	flex-direction: column;
	gap: 115px;
}

.sectionTitling {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 64px;

	svg {
		width: 409px;
		height: 350px;
	}
}

.text {
	max-width: 678px;
}

.sectionTitle {
	color: $black;
	font-family: Open Sans;
	font-size: 54px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin-top: 0;
	margin-bottom: 24px;
	text-transform: uppercase;
}

.sectionText {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	margin: 0;
	padding-bottom: 16px;
}

.vacanciesList {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 32px;
}

@media all and (max-width: 767px) {
	.content {
		width: $mobilePageWidth;
		padding: 40px 0 40px;
		flex-direction: column;
		gap: 43px;
	}
	.sectionTitling {
		flex-direction: column;
		align-items: center;
		gap: 27px;
		svg {
			max-width: calc(100% - 2 * 22px);
		}
	}
	.sectionTitle {
		font-size: 36px;
		line-height: 1;
		margin-top: 12px;
		margin-bottom: 16px;
	}
	.sectionText {
		font-size: 15px;
		line-height: 1.5;
	}
	.vacanciesList {
		grid-template-columns: repeat(1, 1fr);
		gap: 16px;
	}
}
