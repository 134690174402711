@import '../../../../styles/variables.scss';

.content {
	display: flex;
	flex-direction: row;
	gap: 66px;
	align-items: center;
	width: $desktopPageWidth;
	margin: 168px auto;

	svg {
		width: 454px;
		height: 326px;
		flex-shrink: 0;
	}
}

.sectionTitle {
	color: $black;
	font-family: Open Sans;
	font-size: 54px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin-top: 2px;
	margin-bottom: 24px;
	text-transform: uppercase;
}

.sectionText {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	margin: 0 0 16px;
}

@media all and (max-width: 767px) {
	.content {
		flex-direction: column-reverse;
		gap: 60px;
		width: $mobilePageWidth;
		margin: 0 auto 88px;

		svg {
			max-width: 100%;
		}
	}

	.sectionTitle {
		font-size: 36px;
		line-height: 1;
		margin-top: 12px;
		margin-bottom: 16px;
	}

	.sectionText {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 8px;
	}
}
