@import '../../styles/variables.scss';

.headerFixed {
	position: fixed;
	top: 0;
	background-color: $white;
	height: 72px;
	width: 100%;
	z-index: 2;
}

.header {
	height: 72px;
}

.content {
	width: $desktopPageWidth;
	margin: 0 auto;
	padding: 22px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.anchors {
	display: flex;
	flex-direction: row;
	gap: 40px;
	list-style: none;
	padding: 0;
}

.anchor {
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	padding: 0;
	color: $black;
	text-decoration: none;
	cursor: pointer;
}

.anchor:hover {
	text-decoration: underline;
}

.active {
	text-decoration: underline;
}

.burger {
	display: none;
}

@media all and (max-width: 767px) {
	.headerFixed {
		height: 44px;
	}
	.header {
		height: 44px;
		background-color: $white;
		z-index: 2;
	}
	.content {
		width: $mobilePageWidth;
		padding: 8px 0;

		& > a > svg:first-child {
			width: 73px;
			height: 28px;
		}
	}
	.anchors {
		display: none;
	}
	.burger {
		display: block;
		width: 24px;
		height: 24px;
	}
}
