@import '../../../../styles/variables.scss';

.contactUs {
	max-width: max-content;
	background-color: $darkBlue;
	padding: 44px 20px 48px;
	display: flex;
	justify-content: space-around;
	gap: 42px;
	border-radius: 40px;
	margin: 10px auto 96px;
	align-self: center;
}

.sectionTitle {
	color: $white;
	font-family: Open Sans;
	font-size: 54px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin-top: 0;
	margin-bottom: 24px;
	text-transform: uppercase;
}

.sectionText {
	color: $white;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	margin-top: 24px;
	margin-bottom: 16px;
}

.list {
	padding: 23px;
	margin: 0;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	color: $white;
}

.form {
	width: 521px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.input {
	padding: 18px 16px;
	color: $black;
	border-radius: 16px;
	border: 1px solid #b9afcc;
	background-color: $white;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	width: 100%;
}

.input::placeholder {
	color: $middleGray;
}

.otherInput {
	height: 96px;
}

.submitButton {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 56px;
	border-radius: 16px;
	border: none;
	background: $lightBlue300;
	margin-top: 16px;
	transition: 0.3s ease;
	cursor: pointer;
}

.submitButton:hover {
	background: $hoverBlue;
}

.buttonText {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px;
	margin: 0;
}

@media all and (max-width: 767px) {
	.contactUs {
		flex-direction: column;
		width: $mobilePageWidth;
		padding: 24px;
		gap: 24px;
		border-radius: 32px;
		margin: 0 auto 40px;
	}

	.sectionTitle {
		font-size: 30px;
		margin-bottom: 16px;
	}

	.sectionText {
		font-size: 16px;
		line-height: 1.25;
		margin-top: 16px;
		margin-bottom: 8px;
	}

	.list {
		font-size: 16px;
		line-height: 1.25;
	}

	.form {
		width: 100%;
	}

	.input {
		padding: 16px;
		font-size: 16px;
		line-height: 1.25;
	}

	.submitButton {
		height: 48px;
		margin-top: 10px;
	}

	.buttonText {
		font-size: 16px;
		line-height: 1.5;
	}
}
