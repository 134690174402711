@import '../../../../styles/variables.scss';

.services {
	background-color: $blue;
	width: 100vw;
}

.content {
	background-color: $blue;
	width: $desktopPageWidth;
	margin: 0 auto;
	padding: 96px 10px 40px;
	display: flex;
	flex-direction: row;
	gap: 117px;
	position: relative;
}

.sticky {
	position: sticky;
	top: 169px;
}

.sectionTitle {
	color: $white;
	font-family: Open Sans;
	font-size: 54px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin-top: 2px;
	margin-bottom: 24px;
	text-transform: uppercase;
}

.sectionText {
	color: $white;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	margin: 0;
}

.cards {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

@media all and (max-width: 767px) {
	.content {
		width: $mobilePageWidth;
		padding: 32px 0 32px;
		flex-direction: column;
		gap: 32px;
	}
	.sticky {
		position: relative;
		top: 0;
	}
	.sectionTitle {
		font-size: 36px;
		line-height: 1;
		margin-top: 12px;
		margin-bottom: 16px;
	}
	.sectionText {
		font-size: 15px;
		line-height: 1.5;
	}
	.cards {
		gap: 16px;
	}
}
