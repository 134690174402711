@import '../../../../styles/variables.scss';

.divider {
	height: 40px;
	// background-color: $white;
}

.container {
	background-color: $blue;
	width: 100vw;
}

.content {
	width: $desktopPageWidth;
	background-color: $blue;
	margin: 0 auto;
	padding: 96px 10px;
	gap: 117px;
	position: relative;
}

.subtitle {
	display: none;
	margin-bottom: 32px;
	color: $white;
	font-size: 16px;
	line-height: 24px;
}

.sectionTitle {
	color: $white;
	font-family: Open Sans;
	font-size: 54px;
	font-style: normal;
	font-weight: 800;
	line-height: 1.18;
	margin-top: 2px;
	margin-bottom: 40px;
	text-transform: uppercase;
}

.cards {
	display: flex;
	justify-content: space-between;
}

@media all and (max-width: 767px) {
	.content {
		width: $mobilePageWidth;
		padding: 32px 0 32px;
		gap: 32px;
	}

	.subtitle {
		display: block;
	}

	.cards {
		flex-direction: column;
	}

	.sectionTitle {
		font-size: 36px;
		line-height: 1;
		margin-top: 12px;
		margin-bottom: 16px;
	}
}
