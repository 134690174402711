@import '../../../../../../styles/variables.scss';

.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.3s ease, transform 0.3s ease;
}

.modalContent {
	background-color: $white;
	border-radius: 15px;
	border-color: transparent;
	padding: 20px;
	max-width: 400px;
	max-height: 90%;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	position: relative;
	background-size: cover;
	background-position: center;
	transition: opacity 0.3s ease, transform 0.3s ease;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.svgContainer {
	display: flex;
	justify-content: center;
}

.modalTitle {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	margin-top: unset;
	margin-bottom: 1rem;
	text-align: center;
}

.modalBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	padding: 10px;
}

.closeBtnOverlay {
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
}

.sectionTitle {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.list {
	list-style-type: none;
	padding: 0;
}

.listItem {
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 0.875rem;
	margin-bottom: 0.75rem;
	position: relative;
	word-wrap: break-word;
}

.listItem::before {
	content: '•';
	position: absolute;
	left: -0.7rem;
	color: $blueText;
}

.contactButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $blueText;
	border-radius: 16px;
	border: none;
	padding: 20px;
	transition: 0.3s ease;
	cursor: pointer;
	height: 42px;
	width: fit-content;
}

.contactButton:hover {
	background-color: $darkBlue;
}

.btnText {
	text-align: center;
	color: white;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 4;
	margin: 0;
}
