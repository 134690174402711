@import '../../../../styles/variables.scss';

.firstScreen {
	position: relative;
	z-index: -1;
	background-repeat: no-repeat;
	background-position: top 171px left 56vw, top -44px left 50vw;
	margin-top: -72px;
}

.content {
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: $desktopPageWidth;
	padding: 241px 10px 168px;
	margin: 0 auto;
}

.text {
	max-width: 682px;
}

.sectionTitle {
	color: $black;
	font-family: Open Sans;
	font-size: 64px;
	font-style: normal;
	font-weight: 800;
	line-height: 1;
	margin-top: 0;

	.highlighted {
		color: $blueText;
		font-style: italic;
		font-weight: 400;
	}
}

.sectionText {
	color: $black;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	margin: 32px 0 40px;
	max-width: 520px;
}

.button {
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: center;
	justify-content: center;
	background-color: $blueText;
	border-radius: 16px;
	border: none;
	padding: 0 16px 0 32px;
	transition: 0.3s ease;
	cursor: pointer;
	height: 56px;
	width: fit-content;

	.buttonText {
		color: $white;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 1.3;
		margin: 0;
	}

	svg path {
		fill: $white;
	}
}

.button:hover {
	background-color: #0550ff;
}

@media all and (max-width: 767px) {
	.firstScreen {
		background-position: top 357px center, top 326px left 6px;
		background-size: calc(100% - 2 * 38px), 124vw;
		margin-top: 0;
	}
	.content {
		width: $mobilePageWidth;
		padding: 16px 0 107vw;
	}
	.sectionTitle {
		font-size: 36px;
	}
	.sectionText {
		color: $black;
		font-size: 16px;
		line-height: 1.5;
		margin: 16px 0 24px;
	}
	.button {
		height: 48px;
		.buttonText {
			font-size: 16px;
			line-height: 1.6;
		}
		svg {
			width: 24px;
			height: 24px;
		}
	}
}
